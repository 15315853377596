import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class ModalConfirmData {
  title: string;
  message: string;
}
@Component({
  selector: 'app-modal-confirm.',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {
  title: string;
  message: string;
  content: ModalConfirmData;
  confirmationButtonLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalConfirmComponent, public dialogRef: MatDialogRef<ModalConfirmComponent>) {}

  ngOnInit() {
    if (this.data) {
      this.title = this.data.title;
      this.message = this.data.message;
      this.confirmationButtonLabel = this.data.confirmationButtonLabel;
    }
  }

  onConfirm(): void {
    if (this.dialogRef) {
      this.dialogRef.close(true);
    }
  }

  onCancel(): void {
    if (this.dialogRef) {
      this.dialogRef.close(false);
    }
  }
}
