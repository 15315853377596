import { Component, OnInit } from '@angular/core';
@Component({
  template: `
    <div class="container" style="text-align:center; font-family:'Ford Antenna'">
      <br />
      <h1 style="font-family: 'Ford Antenna';">Not Authorized!</h1>
      <br />
      <button
        style="border-radius:3px; color: #474c52; background-color: #c6d3de; font-weight: bolder; font-size: 16px;"
        (click)="reloadPage()"
      >
        Back
      </button>
    </div>
  `,
})
export class NotAuthorizedComponent implements OnInit {
  constructor() {}


  reloadPage() {
    /*
    const form = document.createElement('form');
    form.action = `${environment.agendaUrl}/portal/dashboardDealer.do?acao=iniciar`;
    form.method = 'POST';
    form.target = '_top';
    document.body.appendChild(form);
    form.submit();
     */
  }

  ngOnInit() {}
}
