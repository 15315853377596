import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import {APP_INITIALIZER, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {ToastrModule} from 'ngx-toastr';
import {AppComponent} from './app.component';
import {AppInterceptor} from './app.interceptor';
import {AppRoutingModule} from './app.routing.module';
import {LoginComponent} from './login/login.component';
import {NotAuthorizedComponent} from './not-authorized';
import {AdminGuardService} from './security/admin-guard.service';
import { ContentBodyComponent } from './shared/components/template/content-body/content-body.component';
import {FormatterService} from './shared/formatter.service';
import { SharedModule } from './shared/shared.module';


/**
 * Register l10n locale from pt
 * @See {@link 'https://github.com/angular/angular/tree/master/packages/common/locales'}
 */
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEs, 'es');


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    translate.setDefaultLang('pt_BR');
    try {
      await translate.use('pt_BR').toPromise();
    } catch (err) {
    }
  };
}

export function localeIdLoader(translationService: TranslateService): string {
  return translationService.currentLang;
}

@NgModule({
  declarations: [AppComponent, ContentBodyComponent, LoginComponent, NotAuthorizedComponent],
  imports: [
    SharedModule,
    BrowserModule,
    FilterPipeModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    AdminGuardService,
    FormatterService,
    {
      provide: LOCALE_ID,
      useFactory: localeIdLoader,
      deps: [TranslateService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
}
