import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { DataService } from './dataservice.service';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    public dataService: DataService,
    private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modified = req.clone();

    if (
      req.url.includes(environment.baseUrl) ||
      req.url.includes(environment.transformationUrl) ||
      req.url.includes(environment.dealerMediaUri) ||
      req.url.includes(environment.agendaOwnersUrl) ||
      req.url.includes(environment.agendaDealerUrl) ||
      req.url.includes(environment.agendaCampaigns)
    ) {
      const modifiedRequest = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.dataService.getTokenUser()}`,
          countryCode: this.dataService.getCountryCode(),
          divisionCode: '' + this.dataService.getDivisionCode(),
          dealerCode: '' + this.dataService.getDealerCode(),
        },
      });
      return next.handle(modifiedRequest);
    }

    return next.handle(modified).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          this.router.navigate(['/not-authorized']);
        }
        return throwError(error);
      })
    );
  }
}
