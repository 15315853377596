<div class="stf-modal stf-modal--show stf-modal__dynamic">
  <div class="stf-modal__dialog">
    <div class="stf-modal__content">
      <h1 class="stf-modal__title">
        <span *ngIf="title">{{ title | translate }}</span>
      </h1>
      <div class="stf-modal__text">
        <span *ngIf="message">{{ message | translate }}</span>
      </div>
    </div>
    <div class="stf-modal__controls">
      <button class="stf-modal__button stf-modal__button--cancel" (click)="onCancel()">
        {{ 'buttons.cancel' | translate }}
      </button>
      <button class="stf-modal__button stf-modal__button--confirm" (click)="onConfirm()">
        {{ 'buttons.confirm' | translate }}
      </button>
    </div>
  </div>
</div>
