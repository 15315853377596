import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleTabComponent } from './components/simple-tab/simple-tab.component';
import { MultiOptionsSelectComponent } from './components/multi-options-select/multi-options-select.component';
import { GenericCardComponent } from './components/generic-card/generic-card.component';
import { DragDropDirective } from './drag-drop.directive';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FormErrorMessageDirective, FORM_ERROR_MESSAGES } from './form/form-error-message/form-error-message.directive';
import { FormErrorMessage } from './form/form-error-message/form-error-message';
import { SafeResourcePipe } from './pipe/safe-resource.pipe';

@NgModule({
  declarations: [
    SimpleTabComponent,
    MultiOptionsSelectComponent,
    GenericCardComponent,
    DragDropDirective,
    ModalConfirmComponent,
    FormErrorMessageDirective,
    SafeResourcePipe
   ],
  imports: [
    CommonModule,
    TranslateModule.forChild({}),
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTableModule,
  ],
  exports: [
    CommonModule,
    SimpleTabComponent,
    MultiOptionsSelectComponent,
    TranslateModule,
    FormsModule,
    DragDropDirective,
    GenericCardComponent,
    ModalConfirmComponent,
    MatTableModule,
    FormErrorMessageDirective,
    SafeResourcePipe
  ],
    providers: [
    {
      provide: FORM_ERROR_MESSAGES,
      useValue: new FormErrorMessage(),
    },
  ],
})
export class SharedModule {}
