import { FeatureManagementComponent } from './modules/feature/feature-management/feature-management.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './login/login.component';
import {NotAuthorizedComponent} from './not-authorized';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'campaign', loadChildren: './modules/campaign/campaign.module#CampaignModule'},
  {path: 'fordpass-control', loadChildren: './modules/fordpass-control/fordpass-control.module#FordpassControlModule'},
  {path: 'feature', loadChildren: './modules/feature/feature.module#FeatureModule'},
  {path: 'country', loadChildren: './modules/country-register/country-register.module#CountryRegisterModule'},
  {path: 'not-authorized', component: NotAuthorizedComponent},
  {path: '**', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
}
