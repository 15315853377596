<div class="tab">
  <div
    *ngFor="let tab of tabs; let i = index"
    (click)="setActiveTab(tab)"
    class="tab__item"
    [ngClass]="{ 'tab__item--active': activeTab === tab }"
  >
    {{ tab.label | translate }}
  </div>
</div>
