import { Directive, ElementRef, HostListener, InjectionToken, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormErrorMessage } from './form-error-message';

export interface FormError {
  key: string;
  value: string;
}

/**
 * Provide this value to set the messages used by `appFormErrorMessage`
 *
 * Can be overried using provide like
 *
 * providers: [
 *   {
 *     provide: FORM_ERROR_MESSAGES,
 *     useValue: new FormErrorMessage(),
 *   },
 * ],
 */
export const FORM_ERROR_MESSAGES = new InjectionToken<FormErrorMessage>('FORM_ERROR_MESSAGES');

@Directive({
  selector: '[appFormErrorMessage]',
})
export class FormErrorMessageDirective implements OnInit, OnDestroy {
  @Input() id: string;

  @Input() customFormErrorMessages: FormError[];

  statusChangeSubscription: Subscription;

  private errorSpanId = '';
  private formErrorMessages: FormErrorMessage;
  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService, private elRef: ElementRef, private control: NgControl, private injector: Injector) {}

  ngOnInit(): void {
    // get error messages provided
    this.formErrorMessages = this.injector.get(FORM_ERROR_MESSAGES);

    // get form control and subscribe it status changes
    this.errorSpanId = this.id + '-error';
    this.statusChangeSubscription = this.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(status => {
      if (status === 'INVALID') {
        this.showError();
      } else {
        this.removeError();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  @HostListener('blur', ['$event'])
  handleBlurEvent(event) {
    // This is needed to handle the case of clicking a required field and moving out.
    // Rest all are handled by status change subscription
    if (this.control.errors) {
      this.showError();
    } else {
      this.removeError();
    }
  }

  private showError() {
    this.removeError();
    // get erros from controls
    const valErrors: ValidationErrors = this.control.errors;
    const errorKey = Object.keys(valErrors)[0];
    // get message of customFormErrorMessages or `FORM_ERROR_MESSAGES` provided
    let formErrorMessage;
    if (this.customFormErrorMessages) {
      formErrorMessage = this.customFormErrorMessages.find(x => x.key === errorKey);
    }
    if (!formErrorMessage) {
      formErrorMessage = this.formErrorMessages.getMessages().find(x => x.key === errorKey);
    }

    if (formErrorMessage) {
      // add span with error message
      const errorMsg = this.translateService.instant(formErrorMessage.value, valErrors);
      const errSpan = '<span class="form__invalid-field-message" id="' + this.errorSpanId + '">' + errorMsg + '</span>';
      this.elRef.nativeElement.parentElement.insertAdjacentHTML('beforeend', errSpan);
      // set a error class on form control
      this.elRef.nativeElement.classList.add('form__invalid-field');
    }
  }

  private removeError(): void {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) {
      // remove error class on form control
      this.elRef.nativeElement.classList.remove('form__invalid-field');
      // remove error span
      errorElement.remove();
    }
  }
}
