import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../dataservice.service';
import { LoginService } from './services/login.service';
import { FormatterService } from '../shared/formatter.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  page = '';

  constructor(
    public dataService: DataService,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private router: Router,
    private formatter: FormatterService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const {
        page,
        user,
        profiles,
        countryCode,
        divisionCode,
        dealerCode,
        region,
        language,
        ...optionalParams
      } = params;
      this.dataService.setRegion(region);
      this.dataService.setLanguage(language + '_' + region);
      this.dataService.setTokenUser(user);
      this.dataService.setProfiles(profiles);
      this.dataService.setCountryCode(countryCode);
      this.dataService.setDivisionCode(divisionCode);
      this.dataService.setDealerCode(dealerCode);
      this.page = page;
      this.loginService.registerLanguage(language, region);
      this.navigateToPage(optionalParams);
    });
  }

  navigateToPage(optionalParams: {}): void {
    if (this.page !== undefined) {
      this.router.navigate(['/' + this.page], { queryParams: optionalParams });
    } else {
      this.router.navigate(['/not-authorized']);
    }
  }
}
