<div class="dropdown" [id]="id">
  <button class="drop-toggle btn flat" [ngClass]="disableSelect ? 'disable-drop' : 'enable-drop'"
    [disabled]="disableSelect" (click)="showDropDown=!showDropDown">
    <span *ngIf="checkedList.length<=0">{{placeholderText}}</span>
    <span *ngIf="checkedList.length>0">{{checkedList.join(', ')}}</span>
    <i class="fa fa-angle-down"></i>
  </button>
  <div class="drop-show" *ngIf="showDropDown">
    <label [for]="'all' + id" *ngIf="showSelectAll">
      <div class="drop-field">
        <input type="checkbox" [id]="'all' + id" (click)="selectAllItems(id)" />
        <span>{{'campaign.create-new.card3.all-services' | translate }}</span>
      </div>
      <div class="line-div"></div>
    </label>
    <label *ngFor="let item of list">
      <div class="drop-field">
        <input type="checkbox" [(ngModel)]="item.isChecked"
          (change)="getSelectedValue(item.isChecked, item.shortName)" />
        <span>{{item.name}}</span>
      </div>
      <div class="line-div"></div>
    </label>
  </div>
</div>
