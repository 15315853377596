import { Component, EventEmitter, Input, Output } from '@angular/core';

export class AppTab {
  /**
   * label of tab
   */
  label: string;

  /**
   * unique key for tab
   */
  key: string;
}

@Component({
  selector: 'app-simple-tab',
  templateUrl: './simple-tab.component.html',
  styleUrls: ['./simple-tab.component.scss'],
})
export class SimpleTabComponent {
  /**
   * tabs for the screen
   */
  @Input()
  tabs: AppTab[] = [];

  /**
   * The active tab selected two-way
   */
  @Input()
  activeTab: AppTab;
  @Output()
  activeTabChange = new EventEmitter<AppTab>();

  setActiveTab(val: AppTab) {
    this.activeTab = val;
    this.activeTabChange.emit(val);
  }
}
