import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {DataService} from '../dataservice.service';

@Injectable()
export class AdminGuardService implements CanActivate {

  constructor(
    private dataService: DataService,
    private router: Router) {
  }

  canActivate(): boolean {
    if (this.dataService.isAdmin()) {
      return true;
    }
    this.router.navigate(['/not-authorized']);
    return false;
  }

}
