import { FormError } from './form-error-message.directive';

/**
 * Class used to provide the message for `FORM_ERROR_MESSAGES` used on directive `appFormErrorMessage`
 */
export class FormErrorMessage {
  public getMessages(): FormError[] {
    return [
      { key: 'required', value: 'global.form.message.required_field' },
      { key: 'min', value: 'global.form.message.min_value' },
      { key: 'max', value: 'global.form.message.max_value' },
      { key: 'invalidDateBefore', value: 'global.form.message.date_before_invalid' },
      { key: 'invalidDateAfter', value: 'global.form.message.date_after_invalid' },
      { key: 'invalidDate', value: 'global.form.message.date_invalid' },
    ];
  }
}
