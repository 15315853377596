import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multi-options-select',
  templateUrl: './multi-options-select.component.html',
  styleUrls: ['./multi-options-select.component.scss']
})
export class MultiOptionsSelectComponent implements OnInit {
  @Input() id: string;
  @Input() list: any[];
  @Input() disableSelect: boolean;
  @Input() showSelectAll = false;
  @Input() placeholderText: string;
  @Output() shareCheckedList = new EventEmitter();
  showDropDown: boolean;
  checkedList: any[];
  isCheckAllList = false;

  constructor() {
    this.checkedList = [];
  }

  ngOnInit(): void {
    this.listenCheckClickOut();
  }

  listenCheckClickOut() {
    window.addEventListener('click', event => this.checkClickOut(event));
  }

  checkClickOut(event) {
    if (!document.getElementById(this.id)?.contains(event.target as Node)) {
      this.showDropDown = false;
    }
  }

  getSelectedValue(status: Boolean, value: String) {
    if (status) {
      this.checkedList.push(value);
    } else {
      const index = this.checkedList.indexOf(value);
      this.checkedList.splice(index, 1);
    }
    this.sortCheckedList();
    this.shareCheckedlist();
  }

  sortCheckedList() {
    this.checkedList.sort(function (a, b) {
      return a.toString().localeCompare(b.toString(), undefined, { numeric: true });
    });
  }

  shareCheckedlist() {
    this.shareCheckedList.emit(this.list);
  }

  selectAllItems() {
    if (this.isCheckAllList) {
      this.checkedList = [];
      this.updateList(false);
      this.isCheckAllList = false;
    } else {
      this.checkedList = this.list.map(item => item.shortName);
      this.updateList(true);
      this.isCheckAllList = true;
    }

    this.sortCheckedList();
    this.shareCheckedlist();
  }

  updateList(isChecked: boolean) {
    this.list.forEach(item => item.isChecked = isChecked);
  }

}
