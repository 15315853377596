export const httpCode = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

export enum UserGroup {
  ADMIN = '520',
  FORDIT = '561',
}

export enum DiscountType {
  PERCENTAGE = 'PER',
  MONETARY_VALUE = 'VAL',
}

export enum ScheduleOringinType {
  FORDPASS = 'FPS',
  WEB = 'WEB',
  DEALER = 'DLR',
}

export enum PlanType {
  REVIEW_PLAN = 'RVP',
  OTHER_PLANS = 'OTP',
  REVIEW_AND_OTHER_PLANS = 'RAO',
}

export enum ParticipantType {
  MODEL_GROUP = 'MGP',
  CHASSIS_LIST = 'CHL',
}

export enum TabType {
  REVISION = 'REVISION',
  SERVICE = 'SERVICE',
  MASK = 'MASK',
  BUSINESSRULE = 'BUSINESSRULE'
}

export const country_code = {
  BRAZIL: 'BRA',
  ARGENTINA: 'ARG',
  CHILE: 'CHI',
  COLOMBIA: 'COL',
  EQUADOR: 'EQU',
  PERU: 'PER',
  VENEZUELA: 'VEN',
  INDIA: 'IND',
  UNITED_STATES: 'USA',
  THAILAND: 'THA',
  PHILIPPINES: 'PHL',
  VIETNAM: 'VNM',
  NEW_ZEALAND: 'NZL'
};

export const CountryMaskType = {
  CAR_PLATE: '',
  VIN: '',
  NATIONAL_IDENTITY: '',
  NATIONAL_IDENTITY_COMPANY: ''
};

export enum CountryIMG {
  THA = 'Thailand',
  VNM = 'Vietnam',
  PHL = 'Philippines',
  NZL = 'New Zealand',
}

export enum StatesTitle {
  HISTORY = 'OSR_FLOW_DISPLAY_HISTORY',
  SCHEDULE =  'OSR_FLOW_DISPLAY_SCHEDULE',
  SHELL = 'OSR_FLOW_DISPLAY_SHELL',
  STATUS = 'OSR_FLOW_DISPLAY_STATUS',
  ALL_STREAMS = 'OSR_FLOW_DISPLAY_ALL-STREAM',
  ALL = 'all'
}
