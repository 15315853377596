import { Injectable, OnInit } from '@angular/core';

import { JwtModel } from './shared/model/jwt.model';
import { JwtFilter } from './security/jwt-filter';
import { UserGroup } from './utils/constants';
import { CampaignModel } from './shared/model/campaign.model';

// Class used to pass data through diferent components that are not parent/child of each other
@Injectable({
  providedIn: 'root',
})
export class DataService {
  private tokenUser: string;
  private countryCode: string;
  private divisionCode: string;
  private dealerCode: string;
  private region: string;
  private language: string;
  private profiles: string;


  getTokenUser(): string {
    const tmp: string = sessionStorage.getItem('tokenUser');
    if (tmp !== undefined && tmp !== null) {
      this.tokenUser = tmp;
    }
    return this.tokenUser;
  }

  getJwtModel(): JwtModel {
    const jwt = {
      country: this.getCountryCode(),
      sub: this.getDealerCode(),
      division: this.getDivisionCode(),
      user: JSON.parse(atob(this.getTokenUser().split('.')[1])).sub,
      locale: {
        language: this.getLanguage(),
        region: this.getRegion(),
      },
      groups: this.getProfiles().split(','),
    };
    return jwt;
  }

  setTokenUser(tokenUser: string): void {
    sessionStorage.setItem('tokenUser', tokenUser);
  }

  setToken(token: string): void {
    sessionStorage.setItem('token', token);
  }

  getLocale(): any {
    const {
      country,
      locale: { language, region },
    } = this.getJwtModel();
    return { country, language, region };
  }

  setDivisionCode(divisionCode: string): void {
    sessionStorage.setItem('divisionCode', divisionCode);
  }

  getDivisionCode(): number {
    const tmp: string = sessionStorage.getItem('divisionCode');
    if (tmp !== undefined && tmp !== null) {
      this.divisionCode = tmp;
    }
    return Number(this.divisionCode);
  }

  setDealerCode(dealerCode: string): void {
    sessionStorage.setItem('dealerCode', dealerCode);
  }

  getDealerCode(): number {
    const tmp: string = sessionStorage.getItem('dealerCode');
    if (tmp !== undefined && tmp !== null) {
      this.dealerCode = tmp;
    }
    return Number(this.dealerCode);
  }

  setRegion(region: string): void {
    sessionStorage.setItem('region', region);
  }

  getRegion(): string {
    const tmp: string = sessionStorage.getItem('region');
    if (tmp !== undefined && tmp !== null) {
      this.region = tmp;
    }
    return this.region;
  }

  setLanguage(language: string): void {
    sessionStorage.setItem('language', language);
  }

  getLanguage(): string {
    const tmp: string = sessionStorage.getItem('language');
    if (tmp !== undefined && tmp !== null) {
      this.language = tmp;
    }
    return this.language;
  }

  setProfiles(profiles: string): void {
    sessionStorage.setItem('profiles', profiles);
  }

  getProfiles(): string {
    const tmp: string = sessionStorage.getItem('profiles');
    if (tmp !== undefined && tmp !== null) {
      this.profiles = tmp;
    }
    return this.profiles;
  }

  setCountryCode(countryCode: string): void {
    sessionStorage.setItem('countryCode', countryCode);
  }

  getCountryCode(): string {
    const tmp: string = sessionStorage.getItem('countryCode');
    if (tmp !== undefined && tmp !== null) {
      this.countryCode = tmp;
    }
    return this.countryCode;
  }

  isAdmin() {
    return this.getJwtModel().groups?.some(
      (groupId) => groupId === UserGroup.ADMIN
    );
  }

  isFordIT() {
    return this.getJwtModel().groups?.some(
      (groupId) => groupId === UserGroup.FORDIT
    );
  }

  setCampaignData(campaignModel: CampaignModel): void {
    sessionStorage.setItem('campaign-data', JSON.stringify(campaignModel));
  }

  getCampaignData(): CampaignModel {
    return JSON.parse(sessionStorage.getItem('campaign-data'));
  }
}
