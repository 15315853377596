import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/dataservice.service';

// Class used to pass data through diferent components that are not parent/child of each other
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private translate: TranslateService,
    private dataService: DataService
  ) {}

  registerLanguage(language: string, region: string) {
    this.translate.setDefaultLang(language + '_' + region);
    this.translate.use(language + '_' + region);
  }
}
